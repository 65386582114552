import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig } from 'axios';

// Configure interceptors for any API instance
const configureInterceptors = (apiInstance: AxiosInstance): void => {
  apiInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      // Set header in global axios
      const AUTH_TOKEN = response.headers["authorization-token"];
      if (AUTH_TOKEN) {
        axios.defaults.headers.common.Authorization = `Bearer ${AUTH_TOKEN}`;
        localStorage.setItem("auth_token", AUTH_TOKEN);
      }
      return response;
    },
    (error: any) => Promise.reject(error)
  );

  apiInstance.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const token = localStorage.getItem("auth_token");
      if (token != null) {
        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (err: any) => Promise.reject(err)
  );
};

// Create a default API instance with interceptors
const createDefaultApi = () => {
  const API = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Accept-Version": "v1"
    }
  });

  // Apply interceptors
  configureInterceptors(API);
  
  return API;
};

// Default API instance
const DEFAULT_API = createDefaultApi();

// Create a custom API instance with a specific base URL
export function createApi(baseURL: string) {
  const customAPI = axios.create({
    baseURL,
    withCredentials: true,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    }
  });

  // Apply the same interceptors to custom API
  configureInterceptors(customAPI);

  return customAPI;
}

// Main API call function
export default function callApi(method: string, path: string, options?: any, baseURL?: string) {
  const API = baseURL ? createApi(baseURL) : DEFAULT_API;

  const axiosOptions = {
    data: {},
    method,
    params: {},
    url: path,
    ...options
  };

  return API(axiosOptions);
}