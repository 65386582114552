import { AdCreative } from 'src/store/socials';

const FLOW_FUEL_PREFILL_TEXT = {
  name: '[property.listingStatus]!',
  message: 'Book a viewing for this stunning [property.propertyType] in [property.suburb]! 📲🏡',
  description: '✅ [property.bedrooms] bedrooms, [property.bathrooms] bathrooms'
} as const;

export const POST_SETUP_PREFILL_TEXT: Record<string, Pick<AdCreative, 'name' | 'message' | 'description'>> = {
  flowFuelCustom: FLOW_FUEL_PREFILL_TEXT,
  promoteListing: FLOW_FUEL_PREFILL_TEXT,
  festiveSeason: {
    name: 'Happy holidays! 🎉',
    message: 'Cheers to a well-deserved holiday and a very happy new year! Thank you for your support during 2022 ⭐',
    description: 'From our family to yours'
  },
  onShow: {
    name: 'ON SHOW in [property.suburb]!',
    message: 'View this impressive property now ON SHOW in [property.suburb]! 👀🏡 [DateAndTime].',
    description: '✅ [property.bedrooms] bedrooms, [property.bathrooms] bathrooms'
  },
  generalSalePromotion: {
    name: 'SALE now on! 🎉',
    message: 'Don’t miss out on incredible savings! [Offer]',
    description: 'Get directions'
  },
  productOffers: {
    name: 'On Promotion ⚡',
    message: 'Get great deals on [Product] at Midwest Mall! 🛒',
    description: 'Get directions'
  },
  brandPromotion: {
    name: 'Shop leading brands 🛍️',
    message: 'Discover world-class brands right on your doorstep! Only at Midwest Mall 📍',
    description: 'Get directions'
  },
  newProducts: {
    name: 'NEW at Midwest Mall',
    message: 'NEW products just landed! 😍 Shop the range in store.',
    description: 'Get directions'
  },
  storeLaunch: {
    name: 'Now open 🎉',
    message: 'NEW STORE | Now open in Midwest Mall! 🔥[Store detail]',
    description: 'Get directions'
  },
  financialServices: {
    name: 'Speak to [AgentName]',
    message: 'Speak to [AgentName] for personalised insurance solutions for yourself and your assets, and get real peace of mind today 🤝',
    description: 'Get covered today'
  },
  flowBrandCustom: {
    name: '[Agent Name] - your area specialist!',
    message: 'Contact [Agent Name] for expert advice on buying or selling your home 💪',
    description: 'Buy & sell with the best ✨'
  },
  soldStock: {
    name: 'Just SOLD!  🎉',
    message: '[Agent Name]’s expertise gets results! Contact [Agent Name] today to sell your home quickly, for the best possible price 👌',
    description: '📍 [Suburb]'
  },
  areaSpecialist: {
    name: '[Suburb] area specialist!',
    message: 'Nobody knows [Suburb] better than [Agent Name]! Contact [Agent Name] for expert advice on buying or selling your home 💪',
    description: 'Buy & sell with the best ✨'
  },
  testimonial: {
    name: 'Hear from our ecstatic clients 💬',
    message: 'We’ve helped 100’s of buyers & sellers achieve success! 🏡🏆 Contact us today and experience our excellent service for yourself!',
    description: '⭐⭐⭐⭐⭐'
  },
} as const;

export const TARGETING = {
  minAge: '25',
  maxAge: '60'
} as const;

export const DURATION = {
  days: 3
} as const;

export const newFieldsToMap = [
  'month',
  'year',
  'date',
  'time',
  'address',
  'percentSold',
  'rentalYield',
  'unitsLeft'
];

export const freeFormFields = [
  'backgroundImage',
  'bragSituation',
  'location',
  'clientName',
  'testimonialText1',
  'dateAndTime',
  'offer',
  'product',
  'storeDetail',
  'discountPercentage'
];

export const PREFILL_PLACEHOLDER_MAPPINGS: Record<string, string> = {
  '[Agent Name]': '[agent.displayName]',
  '[Property Type]': '[property.propertyType]',
  '[Suburb]': '[property.suburb]',
  '[FOR SALE]': '[property.listingStatus]',
  '[Beds]': '[property.bedrooms]',
  '[Baths]': '[property.bathrooms]',
  '[DateAndTime]': '[dateAndTime]',
  '[Offer]': '[offer]',
  '[Product]': '[product]',
  '[Store detail]': '[storeDetail]'
};

export const PROPERTY_PREFILL_PLACEHOLDER_MAPPINGS: Record<string, string> = {
  '[property.address]': 'address',
  '[property.suburb]': 'suburb',
  '[property.addressLine1]': 'addressLine1',
  '[property.addressLine2]': 'addressLine2',
  '[property.city]': 'city',
  '[property.province]': 'province',
  '[property.country]': 'country',
  '[property.postCode]': 'postCode',
  '[property.description]': 'description',
  '[property.listingSector]': 'listingSector',
  '[property.listingType]': 'listingType',
  '[property.mandate]': 'mandate',
  '[property.propertyType]': 'propertyType',
  '[property.shortDescription]': 'shortDescription',
  '[property.status]': 'status',
  '[property.bathrooms]': 'bathrooms',
  '[property.bedrooms]': 'bedrooms',
  '[property.parkings]': 'parkings',
  '[property.price]': 'price',
  '[property.erfSize]': 'erfSize',
  '[property.floorSize]': 'floorSize',
  '[property.listingStatus]': 'listingStatus',
  '[property.title]': 'title',
  '[property.vehicleType]': 'vehicleType',
  '[property.vehicleMake]': 'vehicleMake',
  '[property.vehicleModel]': 'vehicleModel',
  '[property.vehicleYear]': 'vehicleYear',
  '[property.vehicleMileage]': 'vehicleMileage',
  '[property.vehicleFuelType]': 'vehicleFuelType',
  '[property.vehicleEngine]': 'vehicleEngine',
  '[property.vehicleTransmission]': 'vehicleTransmission',
  '[property.vehicleBodyType]': 'vehicleBodyType',
  '[property.vehicleSeatingCapacity]': 'vehicleSeatingCapacity',
  '[property.vehicleColor]': 'vehicleColor',
};

export const PRODUCT_PREFILL_PLACEHOLDER_MAPPINGS: Record<string, string> = {
  '[condition]': 'condition',
  '[category]': 'category',
  '[subCategory]': 'subCategory',
  '[group]': 'group',
  '[gender]': 'gender',
  '[color]': 'color',
  '[material]': 'material',
  '[pattern]': 'pattern',
  '[length]': 'length',
  '[height]': 'height',
  '[width]': 'width',
  '[weight]': 'weight'
};

export const LISTING_PREFILL_PLACEHOLDER_MAPPINGS: Record<string, string> = {
  '[title]': 'title',
  '[description]': 'description',
  '[status]': 'status',
  '[disposition]': 'disposition',
  '[size]': 'size',
  '[price]': 'price'
};

export const ADDRESS_PREFILL_PLACEHOLDER_MAPPINGS: Record<string, string> = {
  '[address.suburb]': 'suburb',
};

export const AGENT_PREFILL_PLACEHOLDER_MAPPINGS: Record<string, string> = {
  '[agent.firstName]': 'firstName',
  '[agent.lastName]': 'lastName',
  '[agent.displayName]': 'displayName',
  '[agent.email]': 'email',
  '[agent.contactNumber]': 'contactNumber'
};

export const ORGANISATION_PREFILL_PLACEHOLDER_MAPPINGS: Record<string, string> = {
  '[organisation.name]': 'name'
};

export const ALLOW_MOBILE_DEVICES = true;

export const HEADLINE_MAX_BYTES = 255;
