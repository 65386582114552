import React from 'react';

import MuiSvgIcon from '@material-ui/core/SvgIcon';

import { IconProps } from '../icons';

const InfoIcon: React.FunctionComponent<IconProps> = ({
  nativeColor,
  ...props
}) => (
  <MuiSvgIcon {...props} viewBox="0 0 24 24" fontSize="inherit">
    <svg width={24} height={24} {...props}>
      <g
        stroke={nativeColor}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={12} cy={12.001} r={11} />
        <path d="M14.5 17.005H13a1 1 0 0 1-1-1v-6.5a.5.5 0 0 0-.5-.5H10M11.745 6.5a.25.25 0 1 0 0 .5.25.25 0 0 0 0-.5" />
      </g>
    </svg>
  </MuiSvgIcon>
);

export default InfoIcon;
