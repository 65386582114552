import {
  camelCase,
  get,
  isArray,
  isEmpty,
  isNil,
  isString,
  lowerFirst,
  omit,
  pick,
  set,
  size,
  startCase,
} from "lodash";

import {
  ADDRESS_PREFILL_PLACEHOLDER_MAPPINGS,
  AGENT_PREFILL_PLACEHOLDER_MAPPINGS,
  DURATION,
  HEADLINE_MAX_BYTES,
  LISTING_PREFILL_PLACEHOLDER_MAPPINGS,
  ORGANISATION_PREFILL_PLACEHOLDER_MAPPINGS,
  POST_SETUP_PREFILL_TEXT,
  PREFILL_PLACEHOLDER_MAPPINGS,
  PRODUCT_PREFILL_PLACEHOLDER_MAPPINGS,
  PROPERTY_PREFILL_PLACEHOLDER_MAPPINGS,
  TARGETING,
  freeFormFields,
  newFieldsToMap,
} from "./campaign.defaults";
import {
  Colors,
  LocaleSettingsProps,
  Organisation,
  SocialAssetConfigProps,
} from "../store/organisation";
import {
  AdCreative,
  GroupedAdTemplates,
  AdTypesEnum,
  AgentData,
  BrandObjectiveTypes,
  CallToActionKeys,
  CampaignGoalTypes,
  CampaignObjectivesEnum,
  CampaignSteps,
  FacebookPages,
  FieldsToMap,
  FlowCampaignTypesEnum,
  Genders,
  LocationTypes,
  PostSetupParams,
  PostSetupPrefillParams,
  PostSetupPrefillPlaceholders,
  SelectedFlowCampaign,
  SetupTypes,
  Targeting,
  TargetingInterests,
  TargetingInterestsDescriptions,
  Template,
  TemplateTypes,
  AdTemplateGroups,
  TemplateGalleryTab,
  FlowFuelObjectiveTypes,
  FlowCampaign,
  CreativeTypesEnum,
  TemplateRender,
  CustomCreative,
  TemplateField,
  InputTypes,
  ColourMapping,
  PropertyDerivedPrefillPlaceholders,
  AgentDerivedPrefillPlaceholders,
  GeoLocation,
  Entities,
  DerivedFieldKeys,
  ChannelTypes,
  AddressDerivedPrefillPlaceholders,
  SpecialAdCategoryTypes,
  ListingDerivedPrefillPlaceholders,
} from "../store/socials";
import { deriveAreaNameFromAddressResult, getAgentProfileUrl, getFirstTemplate, isNumeric, isOwnerOrAdmin } from "./general";
import { AuthenticatedUser } from "../store/auth";
import { getCampaignCountryBudget } from "./socialMedia";
import { getProtocolCompatibleImageUrl, getUrlViaProxy } from "./imageUrls";
import { AgentOption, ListingData, ContentType, ListingTypes, Property, Product } from "../store/properties";
import { DEFAULT_LOCALE_SETTINGS } from "./defaultLocaleSettings";
import { formatAmount, formatPhone, getDistanceUnit, getMaxRadius } from "./numberFormatter";
import { getMemberByPath } from "./organisation.helper";
import { addressAsString } from './addressFormatter';
import * as listingHelper from './listing.helper';
import { FlowPlan, Frequencies } from '../store/subscriptions';
import { AddressObjectInterface } from '../components/inputs/AddressSearch';

const textEncoder = new TextEncoder();

export const getCasedCampaignGoalType = (
  campaignGoalType: string
): CampaignGoalTypes | null => {
  switch (campaignGoalType.toLowerCase()) {
    case CampaignGoalTypes.FlowFuel.toLowerCase():
      return CampaignGoalTypes.FlowFuel;
    case CampaignGoalTypes.FlowBrand.toLowerCase():
      return CampaignGoalTypes.FlowBrand;
    default:
      return null;
  }
};

export const deriveCampaignObjectiveFromGoalType = (
  campaignGoalType: string
): CampaignObjectivesEnum => {
  if (campaignGoalType === FlowCampaignTypesEnum.FlowBrand)
    return CampaignObjectivesEnum.BrandAwareness;
  return CampaignObjectivesEnum.Conversions;
};

export const deriveFlowCampaignTypeFromGoalType = (
  campaignGoalType: string
): FlowCampaignTypesEnum => {
  if (campaignGoalType === CampaignGoalTypes.FlowFuel)
    return FlowCampaignTypesEnum.FlowFuelBuyers;
  return FlowCampaignTypesEnum.FlowBrand;
};

export const derivePostSetupPrefillText = (
  isCustom: boolean,
  isTemplate: boolean,
  templateType: string,
  campaignGoalType: string,
  templateCategory?: string
): PostSetupParams => {
  const {
    flowFuelCustom,
    flowBrandCustom,
    promoteListing,
    soldStock,
    areaSpecialist,
    testimonial,
  } = POST_SETUP_PREFILL_TEXT;

  const postSetup: PostSetupParams = {
    name: "",
    message: "",
    description: "",
  };

  const camelCasedTemplateCategory = camelCase(templateCategory);
  const categoryPrefillText =
    POST_SETUP_PREFILL_TEXT[camelCasedTemplateCategory];

  if (templateCategory && !isEmpty(categoryPrefillText)) {
    Object.assign(postSetup, { ...categoryPrefillText });
  } else if (isCustom || (isTemplate && templateType === TemplateTypes.LeadGeneration)) {
    switch (campaignGoalType) {
      case CampaignGoalTypes.FlowFuel:
      case FlowCampaignTypesEnum.FlowFuelBuyers:
      case FlowCampaignTypesEnum.FlowFuelRecruit:
      case FlowCampaignTypesEnum.FlowFuelSellers:
        Object.assign(postSetup, { ...flowFuelCustom });
        break;
      case FlowCampaignTypesEnum.FlowBrand:
        Object.assign(postSetup, { ...flowBrandCustom });
        break;
    }
  } else if (isTemplate) {
    switch (templateType) {
      case TemplateTypes.PromoteListing:
        Object.assign(postSetup, { ...promoteListing });
        break;
      case TemplateTypes.SoldStock:
        Object.assign(postSetup, { ...soldStock });
        break;
      case TemplateTypes.AreaSpecialist:
        Object.assign(postSetup, { ...areaSpecialist });
        break;
      case TemplateTypes.Testimonial:
        Object.assign(postSetup, { ...testimonial });
        break;
    }
  }

  return postSetup;
};

export const getDefaultAdCreativeStatePayload = (): AdCreative => {
  return {
    adType: "",
    creativeName: "",
    caption: "",
    calltoAction: CallToActionKeys.ContactUs,
  };
};

export const deriveAdCreativeStateFromDraft = (campaignDraft: FlowCampaign): AdCreative => {
  const adCreative: AdCreative = get(campaignDraft, 'campaignlocaldata.adsets[0].ads[0]', {});
  adCreative.name = adCreative.headline;
  adCreative.fieldsToMap = adCreative.fieldsToMap || [];
  adCreative.templateFields = adCreative.templateFields || [];
  adCreative.listingId = adCreative.listingId || adCreative.propertyId;

  return adCreative;
};

export const getDefaultTargetingStatePayload = (): Targeting => {
  return {
    age_min: TARGETING.minAge,
    age_max: TARGETING.maxAge,
    genders: [Genders.All],
    targetingInterest: TargetingInterests.AllPeople,
    targetingInterestDescription: TargetingInterestsDescriptions.AllPeople,
    geo_locations: {
      custom_locations: [],
      location_types: Object.values(LocationTypes),
    },
  };
};

export const deriveTargetingStateFromDraft = (
  campaignDraft: FlowCampaign,
  organisation: Organisation
): Targeting => {
  const targeting: Targeting = get(
    campaignDraft,
    "campaignlocaldata.adsets[0].targeting",
    {}
  );
  const localeSettings = get(
    organisation,
    "settings.locale",
    DEFAULT_LOCALE_SETTINGS as LocaleSettingsProps
  );
  let remainingRadiusTotal = getMaxRadius(localeSettings);
  const geo_locations: Partial<GeoLocation> = targeting.geoLocations || targeting.geo_locations || {};
  const custom_locations = geo_locations.customLocations || geo_locations.custom_locations || [];
  for (const loc of custom_locations) {
    remainingRadiusTotal -= loc.radius;
  }
  targeting.age_min = targeting.agemin || targeting.age_min || TARGETING.minAge;
  targeting.age_max = targeting.agemax || targeting.age_max || TARGETING.maxAge;
  targeting.genders = isArray(targeting.genders)
    ? targeting.genders
    : [Genders.All];
  targeting.targetingInterest =
    targeting.targetingInterest || TargetingInterests.AllPeople;
  targeting.targetingInterestDescription =
    targeting.targetingInterestDescription ||
    TargetingInterestsDescriptions.AllPeople;
  targeting.geo_locations = {
    custom_locations,
    location_types: Object.values(LocationTypes),
  };
  targeting.remainingRadiusTotal = remainingRadiusTotal;

  return targeting;
};

export const derivePostSetupParams = (
  adCreative: AdCreative
): PostSetupParams => {
  const { name, message, description } = adCreative;

  return {
    name: name || "",
    message: message || "",
    description: description || "",
  };
};

export const derivePostSetupPrefillParams = (
  agentData: AgentData
): Partial<PostSetupPrefillParams> => {
  const { agentName, propertyType, disposition, suburb, beds, baths } =
    agentData;

  return {
    agentName,
    propertyType,
    propertyStatus: disposition,
    propertySuburb: suburb,
    propertyBedrooms: beds,
    propertyBathrooms: baths,
    disposition,
  };
};

export const replacePostSetupPrefillPlaceholders = (
  postSetupParams: PostSetupParams,
  prefillParams: Partial<PostSetupPrefillParams>
): PostSetupParams => {
  const newPostSetupParams: Record<string, any> = { ...postSetupParams };

  const postSetupParamsKeys = Object.keys(postSetupParams);
  const placeholdersKeys = Object.keys(PostSetupPrefillPlaceholders);

  for (const placeholderKey of placeholdersKeys) {
    const prefillParamKey = lowerFirst(placeholderKey);
    if ((prefillParams as any)[prefillParamKey]) {
      for (const postSetupParamKey of postSetupParamsKeys) {
        newPostSetupParams[postSetupParamKey] = newPostSetupParams[
          postSetupParamKey
        ].replaceAll(
          (PostSetupPrefillPlaceholders as any)[placeholderKey],
          (prefillParams as any)[prefillParamKey]
        );
      }
    }
  }

  return newPostSetupParams as PostSetupParams;
};

export const hasPrefillPlaceholders = (str: string) => {
  if (!isString(str)) return false;
  const matches = str.match(/\[[a-zA-Z0-9_. ]+\]/g);
  return !isEmpty(matches);
};

export const bytesLengthFromString = (str: string) =>
  textEncoder.encode(str || "").length;

export const headlineIsWithinMaxBytes = (headline: string) =>
  bytesLengthFromString(headline) <= HEADLINE_MAX_BYTES;

export const mapAndReplacePostSetupPrefillPlaceholders = (
  postSetupParams: PostSetupParams,
  matchedPlaceholders?: Record<string, string[]>,
  placeholderValues?: Record<string, string>,
  previousPlaceholderValues?: Record<string, string>
): PostSetupParams => {
  const newPostSetupParams: Record<string, any> = { ...postSetupParams };

  const postSetupParamsKeys = Object.keys(postSetupParams);

  if (!isEmpty(matchedPlaceholders)) {
    for (const postSetupParamKey of postSetupParamsKeys) {
      if (isArray(matchedPlaceholders![postSetupParamKey])) {
        for (const placeholder of matchedPlaceholders![postSetupParamKey]) {
          const transformedPlaceholder = placeholder.replace(/[.]+/g, "_DOT_");
          const value =
            get(placeholderValues, transformedPlaceholder) || placeholder;
          const previousValue = get(
            previousPlaceholderValues,
            transformedPlaceholder
          );
          if (isString(previousValue)) {
            newPostSetupParams[postSetupParamKey] = newPostSetupParams[
              postSetupParamKey
            ].replaceAll(previousValue, placeholder);
          }
          newPostSetupParams[postSetupParamKey] = newPostSetupParams[
            postSetupParamKey
          ].replaceAll(placeholder, value);
        }
      }
    }
  }

  return newPostSetupParams as PostSetupParams;
};

export const derivePropertyPrefillPlaceholderValues = (
  property: Property,
  organisation: Organisation
) => {
  const keys = Object.keys(PROPERTY_PREFILL_PLACEHOLDER_MAPPINGS);
  const placeholderValues: Record<string, any> = {};
  for (const key of keys) {
    const transformedKey = key.replace(/[.]+/g, "_DOT_");
    switch (key) {
      case PropertyDerivedPrefillPlaceholders.Address:
        placeholderValues[transformedKey] = property.address
          ? addressAsString(property.address)
          : key;
        break;
      case PropertyDerivedPrefillPlaceholders.Bathrooms: {
        const _units = property.units || [];
        const random = Math.floor(Math.random() * _units.length);
        const unit = _units[random];

        placeholderValues[transformedKey] = String(get(unit, "bathrooms", key));
        break;
      }
      case PropertyDerivedPrefillPlaceholders.Bedrooms: {
        const _units = property.units || [];
        const random = Math.floor(Math.random() * _units.length);
        const unit = _units[random];

        placeholderValues[transformedKey] = String(get(unit, "bedrooms", key));
        break;
      }
      case PropertyDerivedPrefillPlaceholders.ErfSize:
        placeholderValues[transformedKey] = String(
          listingHelper.getPropertyErfSize(property) || key
        );
        break;
      case PropertyDerivedPrefillPlaceholders.FloorSize:
        placeholderValues[transformedKey] = String(
          listingHelper.getPropertyFloorSize(property) || key
        );
        break;
      case ListingDerivedPrefillPlaceholders.Disposition:
      case PropertyDerivedPrefillPlaceholders.ListingStatus: {
        if (!property.listingType) placeholderValues[transformedKey] = key;
        else placeholderValues[transformedKey] = listingHelper.deriveDisposition(property);
        break;
      }
      case PropertyDerivedPrefillPlaceholders.Parkings: {
        const _units = property.units || [];
        const random = Math.floor(Math.random() * _units.length);
        const unit = _units[random];

        placeholderValues[transformedKey] = String(get(unit, "parkings", key));
        break;
      }
      case PropertyDerivedPrefillPlaceholders.Price: {
        placeholderValues[transformedKey] = listingHelper.getFormattedListingPrice(property, organisation);
        break;
      }
      case PropertyDerivedPrefillPlaceholders.Suburb:
        placeholderValues[transformedKey] = get(
          property,
          "address.suburb",
          key
        );
        break;
      case PropertyDerivedPrefillPlaceholders.AddressLine1:
        placeholderValues[transformedKey] = get(property, "address.line1", key);
        break;
      case PropertyDerivedPrefillPlaceholders.AddressLine2:
        placeholderValues[transformedKey] = get(property, "address.line2", key);
        break;
      case PropertyDerivedPrefillPlaceholders.City:
        placeholderValues[transformedKey] = get(property, "address.city", key);
        break;
      case PropertyDerivedPrefillPlaceholders.Country:
        placeholderValues[transformedKey] = get(
          property,
          "address.country",
          key
        );
        break;
      case PropertyDerivedPrefillPlaceholders.PostCode:
        placeholderValues[transformedKey] = get(
          property,
          "address.postCode",
          key
        );
        break;
      case PropertyDerivedPrefillPlaceholders.Province:
        placeholderValues[transformedKey] = get(
          property,
          "address.province",
          key
        );
        break;
      default: {
        let entity: Property | Record<string, string> = property;
        if (key.includes(".vehicle")) entity = deriveVehicleFields(property);
        placeholderValues[transformedKey] = get(
          entity,
          PROPERTY_PREFILL_PLACEHOLDER_MAPPINGS[key],
          key
        );
        break;
      }
    }
  }
  return placeholderValues;
};

export const deriveProductPrefillPlaceholderValues = (product: Product, organisation: Organisation): Record<string, string> => {
  const productPrefillPlaceholderMappings = { ...PRODUCT_PREFILL_PLACEHOLDER_MAPPINGS, ...LISTING_PREFILL_PLACEHOLDER_MAPPINGS };
  const keys: string[] = Object.keys(productPrefillPlaceholderMappings);

  const placeholderValues: Record<string, string> = {};

  if (isEmpty(product)) return placeholderValues;

  for (const key of keys) {
    const transformedKey = key.replace(/[.]+/g, '_DOT_');
    switch (key) {
      case ListingDerivedPrefillPlaceholders.Disposition: {
        if (!product.listingType) placeholderValues[transformedKey] = key;
        else placeholderValues[transformedKey] = listingHelper.deriveDisposition(product);
        break;
      }
      case ListingDerivedPrefillPlaceholders.Price: {
        placeholderValues[transformedKey] = listingHelper.getFormattedListingPrice(product, organisation);
        break;
      }
      default:
        placeholderValues[transformedKey] = get(
          product,
          PRODUCT_PREFILL_PLACEHOLDER_MAPPINGS[key] || LISTING_PREFILL_PLACEHOLDER_MAPPINGS[key],
          key
        );
        break;
    }
  }

  return placeholderValues;
}

export const deriveListingPrefillPlaceholderValues = (
  listing: ListingData,
  organisation: Organisation
) => {
  switch (listing.contentType) {
    case ContentType.PROPERTY:
      return derivePropertyPrefillPlaceholderValues(listing as Property, organisation);
    case ContentType.PRODUCT:
      return deriveProductPrefillPlaceholderValues(listing as Product, organisation);
    default:
      return {};
  }
}

export const deriveAddressPrefillPlaceholderValues = (
  addressSearchResult: AddressObjectInterface,
) => {
  const keys = Object.keys(ADDRESS_PREFILL_PLACEHOLDER_MAPPINGS);
  const placeholderValues: Record<string, any> = {};
  for (const key of keys) {
    const transformedKey = key.replace(/[.]+/g, "_DOT_");
    switch (key) {
      case AddressDerivedPrefillPlaceholders.Suburb:
        placeholderValues[transformedKey] = deriveAreaNameFromAddressResult(addressSearchResult) || key;
        break;
      default: {
        placeholderValues[transformedKey] = get(
          addressSearchResult,
          ADDRESS_PREFILL_PLACEHOLDER_MAPPINGS[key],
          key
        );
        break;
      }
    }
  }
  return placeholderValues;
};

export const deriveAgentPrefillPlaceholderValues = (
  agent: AgentOption | AuthenticatedUser
) => {
  const keys = Object.keys(AGENT_PREFILL_PLACEHOLDER_MAPPINGS);
  const placeholderValues: Record<string, string> = {};
  for (const key of keys) {
    const transformedKey = key.replace(/[.]+/g, "_DOT_");
    switch (key) {
      case AgentDerivedPrefillPlaceholders.ContactNumber:
      case AgentDerivedPrefillPlaceholders.Email: {
        placeholderValues[transformedKey] = get(
          agent,
          AGENT_PREFILL_PLACEHOLDER_MAPPINGS[key],
          get(agent, `contact.${AGENT_PREFILL_PLACEHOLDER_MAPPINGS[key]}`, key)
        );
        break;
      }
      case AgentDerivedPrefillPlaceholders.DisplayName:
        placeholderValues[transformedKey] = get(
          agent,
          AGENT_PREFILL_PLACEHOLDER_MAPPINGS[key],
          get(agent, "label", key)
        );
        break;
      default:
        placeholderValues[transformedKey] = get(
          agent,
          AGENT_PREFILL_PLACEHOLDER_MAPPINGS[key],
          key
        );
        break;
    }
  }
  return placeholderValues;
};

export const deriveOrganisationPrefillPlaceholderValues = (
  organisation: Organisation
) => {
  const keys = Object.keys(ORGANISATION_PREFILL_PLACEHOLDER_MAPPINGS);
  const placeholderValues: Record<string, any> = {};
  for (const key of keys) {
    const transformedKey = key.replace(/[.]+/g, "_DOT_");
    placeholderValues[transformedKey] = get(
      organisation,
      ORGANISATION_PREFILL_PLACEHOLDER_MAPPINGS[key],
      key
    );
  }
  return placeholderValues;
};

export const getFormattedNumber = (
  phoneNum: string,
  localeSettings?: LocaleSettingsProps
): string => {
  const countryCode = get(localeSettings, "country.code", "za");
  return phoneNum ? formatPhone(phoneNum, countryCode, localeSettings) : "";
};

export const deriveTargetingInterestAndDescriptionFromDisposition = (
  listingDisposition: ListingTypes,
  campaignGoalType: CampaignGoalTypes
): Pick<Targeting, "targetingInterest" | "targetingInterestDescription"> => {
  let targetingInterest = TargetingInterests.AllPeople;
  let targetingInterestDescription = TargetingInterestsDescriptions.AllPeople;

  if (campaignGoalType !== CampaignGoalTypes.FlowBrand) {
    if ([ListingTypes.Sale, ListingTypes.Auction].includes(listingDisposition)) {
      targetingInterest = TargetingInterests.Buyers;
      targetingInterestDescription = TargetingInterestsDescriptions.Buyers;
    } else if (listingDisposition === ListingTypes.Rent) {
      targetingInterest = TargetingInterests.Tenants;
      targetingInterestDescription = TargetingInterestsDescriptions.Tenants;
    }
  }

  return { targetingInterest, targetingInterestDescription };
};

export const getPrefillText = (template: Template): PostSetupParams => {
  const postSetupPrefill = derivePostSetupPrefillText(
    false,
    true,
    template.type,
    template.campaignGoalType,
    template.category
  );

  const prefillText = {
    name: get(template, "hasCustomCopy")
      ? get(template, "copyData.copyHeadline")
      : postSetupPrefill.name,
    message: get(template, "hasCustomCopy")
      ? get(template, "copyData.copyMainDescription")
      : postSetupPrefill.message,
    description: get(template, "hasCustomCopy")
      ? get(template, "copyData.copyDescription")
      : postSetupPrefill.description,
  };

  return prefillText;
};

export const updateCampaignStatePayloadWithTemplateInfo = (
  campaignPayload: Partial<SelectedFlowCampaign>,
  template: Template,
  isMasterCampaign?: boolean,
  isAdminOrOwner?: boolean,
): void => {
  let adType = campaignPayload.adType;
  if (!adType) {
    adType = template.creativeType === CreativeTypesEnum.Video ? AdTypesEnum.VideoAd : AdTypesEnum.SingleImage;
  }

  const isFlowBrand = campaignPayload.campaignGoalType === CampaignGoalTypes.FlowBrand;
  const isSoldStock = template.type === TemplateTypes.SoldStock;

  const prefillText: Record<string, any> = getPrefillText(template);

  const prefillTextKeys = Object.keys(prefillText);
  const placeholdersKVP: Record<string, Record<string, string> | string[]> = {};
  for (const prefillTextKey of prefillTextKeys) {
    const placeholders =
      prefillText[prefillTextKey].match(/\[[a-zA-Z0-9_. ]+\]/g);
    if (isArray(placeholders)) {
      for (const placeholder of placeholders) {
        let _placeholder =
          PREFILL_PLACEHOLDER_MAPPINGS[placeholder] || placeholder;
        if (
          placeholder === PostSetupPrefillPlaceholders.PropertySuburb &&
          isFlowBrand &&
          !isSoldStock
        ) {
          _placeholder = AddressDerivedPrefillPlaceholders.Suburb;
        }

        if (isEmpty(placeholdersKVP[prefillTextKey]))
          placeholdersKVP[prefillTextKey] = {};
        (placeholdersKVP[prefillTextKey] as any)[_placeholder] = _placeholder;

        if (placeholder !== _placeholder) {
          prefillText[prefillTextKey] = prefillText[prefillTextKey].replaceAll(
            placeholder,
            _placeholder
          );
        }
      }
      placeholdersKVP[prefillTextKey] = Object.values(
        placeholdersKVP[prefillTextKey]
      );
    }
  }

  let templateFields = template.templateFields, templateFieldKeyMappings;
  if (isMasterCampaign && !isEmpty(template.fieldsToMap)) {
    const result = deriveTemplateFields(
      template.fieldsToMap,
      campaignPayload.campaignGoalType as any,
      template.type as any
    );
    if (isArray(result)) templateFields = result;
    else {
      templateFields = result.templateFields;
      templateFieldKeyMappings = result.keyMappings;
    }

    templateFields = templateFields.map(field => {
      let value = field.value;
      if (field.key === Entities.Agent && !value && !isAdminOrOwner)
        value = campaignPayload.owner;
      return {
        ...field,
        value,
      };
    });
  }

  campaignPayload.selectedTemplate = template;
  campaignPayload.adType = adType;
  campaignPayload.campaignObjective = template.campaignObjective;
  campaignPayload.flowCampaignType = template.campaignGoalType;
  Object.assign(campaignPayload.templateRender!, {
    selectedTemplate: template,
  });
  Object.assign(campaignPayload.adCreative!, {
    ...prefillText,
    matchedPlaceholders: placeholdersKVP,
    adType,
    htmlContent: template.htmlURL,
    fieldsToMap: template.fieldsToMap,
    type: template.type,
    title: template.title,
    templateDescription: template.description,
    templateFields,
    templateColours: template.colourMappings,
    templateCreativeType: template.creativeType,
    creatomateTemplateId: template.creatomateTemplateId,
    templateId: template._id,
    templateFieldKeyMappings,
  });
};

export const updateFieldsToMap = (
  fieldsToMap: FieldsToMap[],
  fieldsToUpdate: Partial<FieldsToMap>[]
) => {
  for (const fieldUpdates of fieldsToUpdate) {
    if (isEmpty(fieldUpdates.id)) continue;

    const idx = fieldsToMap.findIndex((field) => field.id === fieldUpdates.id);

    if (idx >= 0) {
      fieldsToMap.splice(idx, 1, {
        ...fieldsToMap[idx],
        ...fieldUpdates,
      });
    }
  }
};

export const updateTemplateFields = (
  templateFields: TemplateField[],
  fieldsToUpdate: Partial<TemplateField>[]
) => {
  for (const fieldUpdates of fieldsToUpdate) {
    if (isEmpty(fieldUpdates.key)) continue;

    const idx = templateFields.findIndex(
      (field) => field.key === fieldUpdates.key
    );

    if (idx >= 0) {
      templateFields.splice(idx, 1, {
        ...templateFields[idx],
        ...fieldUpdates,
      });
    }
  }
};

export const updateTemplateColours = (
  templateColours: ColourMapping[],
  orgColours: Partial<Colors>
) => {
  for (const colourMapping of templateColours) {
    if (isEmpty(colourMapping.key)) continue;

    const value = (orgColours as any)[colourMapping.themeColour] || "";
    if (isString(value) && value.trim()) {
      const key = `${colourMapping.key}.${colourMapping.property}`;
      Object.assign(colourMapping, { key, value });
    }
  }
};

export const deriveFieldsToMapFromAgentData = (
  agentData: AgentData
): Partial<FieldsToMap>[] => {
  const fieldsToMap: Partial<FieldsToMap>[] = Object.entries<string>(
    omit<any>(agentData, ["agentId", "selectedListing", "defaultAgent"])
  ).map(([key, value]) => ({ id: key, value }));
  return fieldsToMap;
};

export const deriveTemplateFieldsFromAgentData = (
  agentData: AgentData
): Partial<TemplateField>[] => {
  const templateFields: Partial<TemplateField>[] = Object.entries<string>(
    pick<any>(agentData, ["selectedListing", "defaultAgent"])
  ).map(([_key, _value]) => {
    let key = _key, value = _value;
    if (key === "selectedListing") {
      key = Entities.Listing;
      value = get(_value, '_id');
    }
    else if (key === "defaultAgent") {
      key = Entities.Agent;
      value = get(_value, 'value');
    }
    return { key, value };
  });
  return templateFields;
};

export const updateCampaignStatePayloadWithListingInfo = (
  campaignPayload: Partial<SelectedFlowCampaign>,
  listing: ListingData,
  organisation: Organisation,
  templateType: TemplateTypes
): void => {
  const listingDisposition = listingHelper.getListingDisposition(listing);
  const images = listingHelper.getListingImages(listing);

  const agentUser = get(listing, "agentUser", get(listing, "landlordUser"));

  const { members, settings } = organisation;

  const localeSettings = get(
    settings,
    "locale",
    DEFAULT_LOCALE_SETTINGS as LocaleSettingsProps
  );

  const listingAgent = getMemberByPath<string>(
    members,
    "user._id",
    agentUser!
  );
  const defaultAgent = listingAgent && listingHelper.deriveAgentOption(listingAgent);

  let unit, address, vehicleFields = {}, beds, baths, parking, floorSize, erfSize, propertyType, auctionFields = {}, productFields = {};
  if (listing.contentType === ContentType.PROPERTY) {
    unit = listingHelper.getPropertyUnit(listing as Property);
    address = get(listing, "address");
    vehicleFields = deriveVehicleFields(listing as Property);
    beds = String(get(unit, "bedrooms") || "");
    baths = String(get(unit, "bathrooms") || "");
    parking = String(get(unit, "parkings") || "");
    floorSize = String(listingHelper.getPropertyFloorSize(listing as Property) || "");
    erfSize = String(listingHelper.getPropertyErfSize(listing as Property) || "");
    propertyType = startCase(get(listing, "propertyType", ""));
    auctionFields = deriveAuctionFields(listing as Property, organisation);
  }
  else if (listing.contentType === ContentType.PRODUCT) {
    const fieldsToMap = get(campaignPayload, "adCreative.fieldsToMap", get(campaignPayload, "selectedTemplate.fieldsToMap")) || [];
    productFields = listingHelper.deriveProductFieldsValues(fieldsToMap, listing as Product);
  }

  const backgroundImage = get(
    images,
    "[0].media.sizes.medium",
    get(
      images,
      "[0].media.url",
      get(images, "[0].url", "")
    )
  );
  const suburb = get(
    address,
    "suburb",
    get(address, "city", "")
  );
  const addressLine1 = get(address, "line1", "");
  const addressLine2 = get(address, "line2", "");
  const city = get(address, "city", "");
  const province = get(address, "province", "");
  const country = get(address, "country", "");
  const postCode = get(address, "postCode", "");
  const fullAddress = get(
    address,
    "fullAddress",
    get(address, "addressString", "")
  );
  const agentAvatar = get(defaultAgent, "profileImageUrl", "");
  const disposition = listingHelper.deriveDisposition(listing);

  let price = listingHelper.getFormattedListingPrice(listing, organisation);

  const listingPlaceholderValues = deriveListingPrefillPlaceholderValues(
    listing,
    organisation
  );
  const agentPlaceholderValues = deriveAgentPrefillPlaceholderValues(
    defaultAgent!
  );
  const organisationPlaceholderValues =
    deriveOrganisationPrefillPlaceholderValues(organisation);
  const placeholderValues = {
    ...listingPlaceholderValues,
    ...agentPlaceholderValues,
    ...organisationPlaceholderValues,
  };

  const agentData = {
    agentId: get(defaultAgent, "value", ""),
    agentName: get(defaultAgent, "label", ""),
    agentEmail: get(defaultAgent, "contact.email", ""),
    agentContactNumber: getFormattedNumber(
      get(defaultAgent, "contact.contactNumber", ""),
      localeSettings
    ),
    agentContactNumberUnformatted: get(defaultAgent, "contact.contactNumber", ""),
    agentAvatar: agentAvatar && getProtocolCompatibleImageUrl(agentAvatar),
    defaultAgent: defaultAgent,
    backgroundImage:
      backgroundImage && getProtocolCompatibleImageUrl(backgroundImage),
    beds,
    baths,
    parking,
    floorSize,
    erfSize,
    propertyStatus: disposition,
    disposition,
    propertyPrice: price,
    price,
    propertyType,
    selectedListing: listing,
    suburb,
    addressLine1,
    addressLine2,
    city,
    province,
    country,
    postCode,
    fullAddress,
    location: suburb,
    ...auctionFields,
    ...vehicleFields,
    ...productFields,
  };

  const adpostDefault = {
    agentName: agentData.agentName || "[Agent Name]",
    suburb: suburb || "[Suburb]",
    beds,
    baths,
    floorSize,
    erfSize,
    propertyType,
    propertyStatus: disposition,
    disposition,
  };

  const postSetupParams = derivePostSetupParams(campaignPayload.adCreative!);
  const updatedPostSetupParams = mapAndReplacePostSetupPrefillPlaceholders(
    postSetupParams,
    get(campaignPayload, "adCreative.matchedPlaceholders"),
    placeholderValues
  );
  const targetingInterests =
    deriveTargetingInterestAndDescriptionFromDisposition(
      listingDisposition as ListingTypes,
      campaignPayload.campaignGoalType as CampaignGoalTypes
    );

  let link;
  if (templateType === TemplateTypes.SoldStock) {
    link = getAgentProfileUrl(
      organisation,
      pick(listingAgent, ["id", "firstName", "lastName"])
    );
  } else if (templateType === TemplateTypes.PromoteListing) {
    link = listing.link;
  }

  campaignPayload.interest = targetingInterests.targetingInterest!;
  campaignPayload.owner = agentData.agentId;
  Object.assign(campaignPayload.targeting!, { ...targetingInterests });
  Object.assign(campaignPayload.templateRender!, {
    location: suburb,
    adpostDefault,
  });
  Object.assign(campaignPayload.templateRender!.agentData!, { ...agentData });
  Object.assign(campaignPayload.adCreative!, {
    ...updatedPostSetupParams,
    propertyId: listing._id,
    listingId: listing._id,
    agentId: agentData.agentId,
    link,
    placeholderValues,
  });
};

export const updateCampaignStatePayloadWithAgentInfo = (
  campaignPayload: Partial<SelectedFlowCampaign>,
  agent: AuthenticatedUser,
  organisation: Organisation
): void => {
  const defaultAgent = listingHelper.deriveAgentOption(agent);
  const localeSettings = get(
    organisation,
    "settings.locale",
    DEFAULT_LOCALE_SETTINGS as LocaleSettingsProps
  );

  const agentPlaceholderValues =
    deriveAgentPrefillPlaceholderValues(defaultAgent);
  const organisationPlaceholderValues =
    deriveOrganisationPrefillPlaceholderValues(organisation);
  const placeholderValues = {
    ...agentPlaceholderValues,
    ...organisationPlaceholderValues,
  };

  const agentData = {
    agentId: defaultAgent.value,
    agentName: defaultAgent.label,
    agentEmail: defaultAgent.contact.email,
    agentContactNumber: getFormattedNumber(
      get(defaultAgent, "contact.contactNumber", ""),
      localeSettings
    ),
    agentContactNumberUnformatted: get(defaultAgent, "contact.contactNumber", ""),
    agentAvatar:
      defaultAgent.profileImageUrl &&
      getProtocolCompatibleImageUrl(defaultAgent.profileImageUrl),
    defaultAgent,
  };

  const adpostDefault = {
    agentName: agentData.agentName || "[Agent Name]",
  };

  const postSetupParams = derivePostSetupParams(campaignPayload.adCreative!);
  const updatedPostSetupParams = mapAndReplacePostSetupPrefillPlaceholders(
    postSetupParams,
    get(campaignPayload, "adCreative.matchedPlaceholders"),
    placeholderValues
  );

  campaignPayload.owner = agentData.agentId;
  Object.assign(campaignPayload.templateRender!.agentData!, { ...agentData });
  Object.assign(campaignPayload.templateRender!, { adpostDefault });
  Object.assign(campaignPayload.adCreative!, {
    ...updatedPostSetupParams,
    agentId: agentData.agentId,
    link: getAgentProfileUrl(
      organisation,
      pick(agent, ["id", "firstName", "lastName"])
    ),
    placeholderValues,
  });
};

export const deriveDefaultRadius = (localeSettings: LocaleSettingsProps): number => {
  let defaultRadius = 3;
  const isHousingSpecialAdCategory =
    get(localeSettings, "specialAdCategory") === SpecialAdCategoryTypes.Housing;
  if (isHousingSpecialAdCategory) {
    const countryCode = String(get(localeSettings, "country.code", "za"));
    const continent = String(get(localeSettings, "continent", "africa"));
    if (
      ["us", "ca"].includes(countryCode.toLowerCase()) ||
      continent.toLowerCase() === "europe"
    ) {
      defaultRadius = 17;
    }
  }
  return defaultRadius;
}

export const updateCampaignTargetingStatePayloadWithListingInfo = (
  campaignPayload: Partial<SelectedFlowCampaign>,
  listing: ListingData,
  localeSettings: LocaleSettingsProps,
): void => {
  const targeting = get(campaignPayload, "targeting", {}) as Targeting;
  const custom_locations = get(targeting, "geo_locations.custom_locations", []);

  const listingDisposition = listingHelper.getListingDisposition(listing);
  const campaignGoalType = get(campaignPayload, "campaignGoalType") as CampaignGoalTypes;
  const { targetingInterest, targetingInterestDescription } = deriveTargetingInterestAndDescriptionFromDisposition(
    listingDisposition as ListingTypes,
    campaignGoalType
  );

  if (isEmpty(targeting.targetingInterest)) {
    Object.assign(campaignPayload.targeting!, { targetingInterest });
  }
  if (isEmpty(targeting.targetingInterestDescription)) {
    Object.assign(campaignPayload.targeting!, { targetingInterestDescription });
  }

  if (isEmpty(custom_locations)) {
    const countryCode = String(get(localeSettings, "country.code", "za"));
    const defaultRadius = deriveDefaultRadius(localeSettings);
    const remainingRadiusTotal = get(campaignPayload, "targeting.remainingRadiusTotal", getMaxRadius(localeSettings));

    if (listing.contentType === ContentType.PROPERTY) {
      const latitude = get(listing, "location.coordinates[0]");
      const longitude = get(listing, "location.coordinates[1]");
      if (listingHelper.validateCoordinates(latitude, longitude)) {
        const defaultLocation = {
          distance_unit: getDistanceUnit(localeSettings, true),
          latitude,
          longitude,
          radius: defaultRadius,
          country: countryCode,
          suburb: get(
            listing,
            "address.suburb",
            get(listing, "address.city", "")
          ),
        };

        const geo_locations = { custom_locations: [defaultLocation] };
        const _remainingRadiusTotal =
          remainingRadiusTotal > Number(defaultLocation.radius)
            ? remainingRadiusTotal - Number(defaultLocation.radius)
            : 0;

        Object.assign(campaignPayload.targeting!, { remainingRadiusTotal: _remainingRadiusTotal, geo_locations });
      }
    }
  }
}

export const deriveDefaultCampaignStatePayload = (
  campaignGoalType: CampaignGoalTypes,
  user: AuthenticatedUser,
  organisation: Organisation,
  template: Template | null,
  listing: ListingData | null,
  agent: AuthenticatedUser | null,
  headOfficeOrganisation?: Partial<Organisation> | null,
  flowPlan?: FlowPlan | null,
  billingFrequency?: Frequencies | null,
  isMasterCampaign?: boolean,
): Partial<SelectedFlowCampaign> => {
  const { _id: orgId, name: orgName, logoUrl, settings } = organisation;

  const socialAssetConfiguration: SocialAssetConfigProps | undefined = get(
    settings,
    "socialAssetConfiguration"
  );
  const fbPages: FacebookPages[] = get(
    socialAssetConfiguration,
    "facebook.facebookpage",
    []
  );

  const defaultFbPage = fbPages.find(fbPage => !!fbPage.isDefault);

  const organisationLocale = get(
    settings,
    "locale",
    DEFAULT_LOCALE_SETTINGS as LocaleSettingsProps
  );
  const budgetPerWeekDataset = getCampaignCountryBudget(organisationLocale);
  const defaultBudgetPerWeek =
    budgetPerWeekDataset![Object.keys(budgetPerWeekDataset!)[0]];

  const selectedDays = get(defaultBudgetPerWeek, "days", DURATION.days);

  const startDate = new Date();
  const endDateTimestamp = new Date().setDate(
    startDate.getDate() + selectedDays
  );

  const isAdminOrOwner = isOwnerOrAdmin(user, organisation);

  const payload: Partial<SelectedFlowCampaign> = {
    adAccountId: get(
      socialAssetConfiguration,
      "facebook.adaccount.adaccountid"
    ),
    campaignGoalType,
    owner: user.id,
    interest: TargetingInterests.AllPeople,
    organisationId: orgId,
    startDate: startDate.toISOString(),
    endDate: new Date(endDateTimestamp).toISOString(),
    setupType: SetupTypes.BLP_AB,
    adType: "",
    campaignObjective: deriveCampaignObjectiveFromGoalType(campaignGoalType),
    flowCampaignType: deriveFlowCampaignTypeFromGoalType(campaignGoalType),
    branch: orgName,
    clientName: orgName,
    commission: get(settings, "flowCommission", 0),
    instagramPageId: get(
      socialAssetConfiguration,
      "facebook.instagrampage[0].pageId",
      ""
    ),
    facebookPageId: defaultFbPage
      ? defaultFbPage.pageId
      : fbPages.length === 1
        ? fbPages[0].pageId
        : "",
    screenStep: CampaignSteps.AdCreative,
    adCreative: {
      ...getDefaultAdCreativeStatePayload(),
      placeholderValues:
        deriveOrganisationPrefillPlaceholderValues(organisation),
    },
    targeting: getDefaultTargetingStatePayload(),
    templateRender: {
      agentData: {
        agentName: "",
        organisationName: orgName,
        organisationLogo: logoUrl && getProtocolCompatibleImageUrl(logoUrl),
      },
      facebook: defaultFbPage
        ? defaultFbPage
        : fbPages.length === 1
          ? fbPages[0]
          : undefined,
    },
    isMasterCampaign,
  };

  let budget, budgetPerWeek, days;
  if (isMasterCampaign && flowPlan && billingFrequency) {
    budget = (flowPlan.isRecurring && billingFrequency === Frequencies.Annually)
      ? flowPlan.annualPrice
      : flowPlan.price;
    budgetPerWeek = 'custom';
    days = flowPlan.duration;
  }

  if (campaignGoalType === CampaignGoalTypes.FlowFuel) {
    if (!budget) budget = get(defaultBudgetPerWeek, "campaignBudget", 0);
    if (!budgetPerWeek) budgetPerWeek = defaultBudgetPerWeek;
    if (!days) days = selectedDays;

    payload.interest = TargetingInterests.Buyers;
    payload.targeting!.targetingInterest = TargetingInterests.Buyers;
    payload.targeting!.targetingInterestDescription =
      TargetingInterestsDescriptions.Buyers;
    payload.selectedFlowFuelObjectiveType = FlowFuelObjectiveTypes.LandingPage;
  } else if (campaignGoalType === CampaignGoalTypes.FlowBrand) {
    payload.selectedBrandObjectiveType = BrandObjectiveTypes.LandingPage;
  }

  if (budget) Object.assign(payload, { budget });
  if (budgetPerWeek) Object.assign(payload, { budgetPerWeek });
  if (days) Object.assign(payload, { days });

  if (isMasterCampaign && flowPlan) {
    const channels = get(flowPlan, 'channels') || [];
    const facebookChannel = channels.find(channel => channel.channel === ChannelTypes.Facebook);
    const adType = get(facebookChannel, 'adType');
    Object.assign(payload, { adType });

    if (adType === AdTypesEnum.LeadAd) {
      //Lead Ads do not support ContactUs, defaulting to LearnMore
      set(payload, 'adCreative.calltoAction', CallToActionKeys.LearnMore);
      //default to lead form objective
      if (campaignGoalType === CampaignGoalTypes.FlowFuel) {
        payload.selectedFlowFuelObjectiveType = FlowFuelObjectiveTypes.LeadForm;
      } else if (campaignGoalType === CampaignGoalTypes.FlowBrand) {
        payload.selectedBrandObjectiveType = BrandObjectiveTypes.LeadForm;
      }
    }
  }

  if (template) {
    updateCampaignStatePayloadWithTemplateInfo(payload, template, isMasterCampaign, isAdminOrOwner);
  }

  if (agent) {
    updateCampaignStatePayloadWithAgentInfo(payload, agent, organisation);
  }

  if (listing) {
    const templateType = get(template, "type", "");
    updateCampaignStatePayloadWithListingInfo(
      payload,
      listing,
      organisation,
      templateType as TemplateTypes
    );
    updateCampaignTargetingStatePayloadWithListingInfo(payload, listing, organisationLocale);
  }

  const adCreativeFieldsToMap = payload.adCreative!.fieldsToMap;
  if (!isEmpty(adCreativeFieldsToMap)) {
    const fieldsToUpdate = deriveFieldsToMapFromAgentData(
      payload.templateRender!.agentData!
    );
    updateFieldsToMap(adCreativeFieldsToMap!, fieldsToUpdate);
  }

  const adCreativeTemplateFields = payload.adCreative!.templateFields;
  if (!isEmpty(adCreativeTemplateFields)) {
    const fieldsToUpdate = deriveTemplateFieldsFromAgentData(
      payload.templateRender!.agentData!
    );
    updateTemplateFields(adCreativeTemplateFields!, fieldsToUpdate);
  }

  const adCreativeTemplateColours = payload.adCreative!.templateColours;
  if (!isEmpty(adCreativeTemplateColours)) {
    const orgColours = get(settings, "colors") || {};
    updateTemplateColours(adCreativeTemplateColours!, orgColours);
  }

  return payload;
};

export const deriveAdTemplateStateFromAdCreative = (
  adCreative: AdCreative,
  flowCampaignType: string,
  campaignObjective: string
): Template | null => {
  if (isEmpty(adCreative.fieldsToMap) && isEmpty(adCreative.templateId))
    return null;
  return {
    _id: adCreative.templateId || "",
    htmlContent: "",
    type: adCreative.type || "",
    title: adCreative.title || "",
    description: adCreative.templateDescription || "",
    htmlURL: adCreative.htmlContent || "",
    thumbnail: adCreative.thumbnail || "",
    fieldsToMap: adCreative.fieldsToMap || [],
    campaignGoalType: flowCampaignType,
    creativeType: adCreative.templateCreativeType || "",
    creatomateTemplateId: adCreative.creatomateTemplateId || "",
    templateFields: adCreative.templateFields || [],
    campaignObjective,
  };
};

export const deriveCustomCreativeStateFromAdCreative = (
  adCreative: AdCreative
): CustomCreative | null => {
  if (!isEmpty(adCreative.fieldsToMap) || !isEmpty(adCreative.templateId))
    return null;

  const customCreative = {
    creativeType: adCreative.videoUrl
      ? CreativeTypesEnum.Video
      : CreativeTypesEnum.Image,
    creativeUrl: adCreative.videoUrl || adCreative.htmlContent || "",
    videoThumbnailUrl: adCreative.videoUrl ? adCreative.thumbnail : null,
  };
  if (customCreative.creativeType === CreativeTypesEnum.Image)
    adCreative.creativeImageUrl = customCreative.creativeUrl;

  return customCreative;
};

export const updateCampaignStatePayloadWithTemplateFields = (
  campaignPayload: Partial<SelectedFlowCampaign>
): void => {
  const fieldsToMap = get(campaignPayload, "adCreative.fieldsToMap", []);
  const fieldIds = freeFormFields.concat(newFieldsToMap);
  const foundFields = fieldIds
    .map((fieldId) => fieldsToMap.find((f: FieldsToMap) => f.id === fieldId))
    .filter((fieldToMap) => fieldToMap !== undefined);

  for (const fieldToMap of foundFields) {
    if (fieldToMap.id === "backgroundImage") {
      campaignPayload.templateRender!.agentData!.backgroundImage =
        fieldToMap.value;
    }
    (campaignPayload.templateRender! as any)[fieldToMap.id] = fieldToMap.value;
  }
};

export const deriveCampaignStateFromDraft = (
  campaignDraft: FlowCampaign,
  organisation: Organisation,
  listing: ListingData | null,
  agent: AuthenticatedUser | null,
  selectedTemplate: Template | null,
  isMasterCampaign?: boolean,
): Partial<SelectedFlowCampaign> => {
  const { _id: orgId, name: orgName, settings, logoUrl } = organisation;

  const {
    campaignlocaldata,
    flowcampaigntype,
    objective,
    owner,
    _id: campaignId,
    campaignmode,
  } = campaignDraft;

  const socialAssetConfiguration: SocialAssetConfigProps | undefined = get(
    settings,
    "socialAssetConfiguration"
  );
  const fbPages: FacebookPages[] = get(
    socialAssetConfiguration,
    "facebook.facebookpage",
    []
  );
  const fbPage = fbPages.find(
    (page: FacebookPages) =>
      page.pageId === get(campaignlocaldata, "facebookPageId", "")
  );

  const organisationLocale = get(
    settings,
    "locale",
    DEFAULT_LOCALE_SETTINGS as LocaleSettingsProps
  );
  const budgetPerWeekDataset = getCampaignCountryBudget(organisationLocale);
  const defaultBudgetPerWeek =
    budgetPerWeekDataset![Object.keys(budgetPerWeekDataset!)[0]];

  const selectedDays = get(defaultBudgetPerWeek, "days", DURATION.days);

  const startDate = new Date();
  const endDateTimestamp = new Date().setDate(
    startDate.getDate() + selectedDays
  );

  const adCreative: AdCreative = deriveAdCreativeStateFromDraft(campaignDraft);
  const targeting: Targeting = deriveTargetingStateFromDraft(
    campaignDraft,
    organisation
  );

  const customCreative = deriveCustomCreativeStateFromAdCreative(adCreative);

  const templateRender: Partial<TemplateRender> = {
    agentData: {
      agentName: "",
      organisationName: orgName,
      organisationLogo: logoUrl && getProtocolCompatibleImageUrl(logoUrl),
    },
    facebook: fbPage,
    isMainDescriptionUpdated: true,
    isHeadlineUpdated: true,
    isDescriptionUpdated: true,
  };

  const payload: Partial<SelectedFlowCampaign> = {
    flowCampaignType: flowcampaigntype,
    campaignObjective: objective,
    campaignGoalType: get(campaignlocaldata, "campaignGoalType", ""),
    owner,
    interest: targeting.targetingInterest,
    organisationId: orgId,
    campaignLocalId: campaignId,
    startDate: startDate.toISOString(),
    endDate: new Date(endDateTimestamp).toISOString(),
    setupType: get(campaignlocaldata, "setupType", ""),
    adType: get(campaignlocaldata, "adType", ""),
    branch: orgName,
    clientName: orgName,
    commission: get(settings, "flowCommission", 0),
    adAccountId: get(campaignlocaldata, "adAccountId", ""),
    facebookPageId: get(campaignlocaldata, "facebookPageId", ""),
    isDraft: campaignmode === "draft",
    instagramPageId: get(campaignlocaldata, "instagramPageId", ""),
    screenStep: get(campaignlocaldata, "screenStep"),
    localCampaignId: campaignId,
    targeting,
    adCreative,
    selectedTemplate,
    customCreative,
    templateRender,
    facebookCampaignId: campaignDraft.facebookcampaignid,
  };

  if (payload.campaignGoalType === CampaignGoalTypes.FlowFuel) {
    const budget = get(defaultBudgetPerWeek, "campaignBudget", 0);

    payload.budget = isMasterCampaign ? get(campaignlocaldata, "budget", 0) : budget;
    payload.budgetPerWeek = isMasterCampaign ? "custom" : defaultBudgetPerWeek;
    payload.days = isMasterCampaign ? get(campaignlocaldata, "days", DURATION.days) : selectedDays;
    payload.selectedFlowFuelObjectiveType = get(
      campaignlocaldata,
      "selectedFlowFuelObjectiveType",
      payload.adType === AdTypesEnum.LeadAd ? FlowFuelObjectiveTypes.LeadForm : FlowFuelObjectiveTypes.LandingPage
    );
    payload.selectedFlowFuelObjectiveTypeUrl = get(
      campaignlocaldata,
      "selectedFlowFuelObjectiveTypeUrl"
    );
  } else if (payload.campaignGoalType === CampaignGoalTypes.FlowBrand) {
    payload.selectedBrandObjectiveType = get(
      campaignlocaldata,
      "selectedBrandObjectiveType",
      payload.adType === AdTypesEnum.LeadAd ? BrandObjectiveTypes.LeadForm : BrandObjectiveTypes.LandingPage
    );
    payload.selectedBrandObjectiveTypeUrl = get(
      campaignlocaldata,
      "selectedBrandObjectiveTypeUrl"
    );
  }

  if (listing) {
    const templateType = get(selectedTemplate, "type", "");
    updateCampaignStatePayloadWithListingInfo(
      payload,
      listing,
      organisation,
      templateType as TemplateTypes
    );
    updateCampaignTargetingStatePayloadWithListingInfo(payload, listing, organisationLocale);
  }

  if (agent) {
    updateCampaignStatePayloadWithAgentInfo(payload, agent, organisation);
  }

  if (selectedTemplate && !customCreative) {
    updateCampaignStatePayloadWithTemplateFields(payload);
  }

  return payload;
};

export const deriveTemplateGalleryTabs = (
  groupedAdTemplates: GroupedAdTemplates,
  organisationName?: string,
  adType?: AdTypesEnum,
  isMasterCampaign?: boolean,
): TemplateGalleryTab[] => {
  const tabs: TemplateGalleryTab[] = [];

  if (!isEmpty(groupedAdTemplates)) {
    if (!isEmpty(groupedAdTemplates.private_video_templates) && (!adType || adType === AdTypesEnum.VideoAd)) {
      tabs.push({
        title: `${organisationName || "My organisation"} Video Templates`,
        label: AdTemplateGroups.PrivateVideoTemplates,
        templates: groupedAdTemplates.private_video_templates,
      });
    }
    if (!isEmpty(groupedAdTemplates.private) && (!adType || [AdTypesEnum.SingleImage, AdTypesEnum.LeadAd].includes(adType))) {
      tabs.push({
        title: `${organisationName || "My organisation"} Image Templates`,
        label: AdTemplateGroups.Private,
        templates: groupedAdTemplates.private,
      });
    }
    if (!isEmpty(groupedAdTemplates.public_video_templates) && (!adType || adType === AdTypesEnum.VideoAd)) {
      tabs.push({
        title: "Public Video Templates",
        label: AdTemplateGroups.PublicVideoTemplates,
        templates: groupedAdTemplates.public_video_templates,
      });
    }
    if (!isEmpty(groupedAdTemplates.public) && (!adType || [AdTypesEnum.SingleImage, AdTypesEnum.LeadAd].includes(adType))) {
      tabs.push({
        title: "Public Templates",
        label: AdTemplateGroups.Public,
        templates: groupedAdTemplates.public,
      });
    }
  }

  if (!isMasterCampaign) {
    //add custom template tab
    tabs.push({
      title: "Upload your design",
      label: "custom",
    });
  }

  return tabs;
};

export const deriveDefaultAdTemplate = (
  groupedAdTemplates: GroupedAdTemplates,
  organisationName?: string,
  adType?: AdTypesEnum,
  isMasterCampaign?: boolean,
): Template | null => {
  if (isEmpty(groupedAdTemplates)) return null;

  let defaultTab = (!isEmpty(groupedAdTemplates.private_video_templates) && (!adType || adType === AdTypesEnum.VideoAd))
    ? AdTemplateGroups.PrivateVideoTemplates
    : (!isEmpty(groupedAdTemplates.private) && (!adType || [AdTypesEnum.SingleImage, AdTypesEnum.LeadAd].includes(adType)))
      ? AdTemplateGroups.Private
      : (!isEmpty(groupedAdTemplates.public_video_templates) && (!adType || adType === AdTypesEnum.VideoAd))
        ? AdTemplateGroups.PublicVideoTemplates
        : (!isEmpty(groupedAdTemplates.public) && (!adType || [AdTypesEnum.SingleImage, AdTypesEnum.LeadAd].includes(adType)))
          ? AdTemplateGroups.Public
          : '';
  const tabs = deriveTemplateGalleryTabs(groupedAdTemplates, organisationName, adType, isMasterCampaign);
  if (isEmpty(defaultTab)) defaultTab = get(tabs, '[0].label');

  return getFirstTemplate(tabs, defaultTab);
};

export const deriveVehicleFields = (
  listing: Property
): Record<string, string> => {
  const vehicleFields: Record<string, any> = {};
  const isVehicle = get(listing, "listingSector") === "vehicle";
  if (isVehicle) {
    const vehicleSpecs = get(listing, "syndicator.doc.specs") || {};
    vehicleFields["vehicleType"] = get(vehicleSpecs, "type");
    vehicleFields["vehicleMake"] = get(vehicleSpecs, "make");
    vehicleFields["vehicleModel"] = get(vehicleSpecs, "model");
    vehicleFields["vehicleYear"] = get(vehicleSpecs, "year");
    vehicleFields["vehicleMileage"] = get(vehicleSpecs, "mileage");
    vehicleFields["vehicleFuelType"] = get(vehicleSpecs, "fuel");
    vehicleFields["vehicleEngine"] = get(vehicleSpecs, "engine");
    vehicleFields["vehicleTransmission"] = get(vehicleSpecs, "transmission");
    vehicleFields["vehicleBodyType"] = get(vehicleSpecs, "style");
    vehicleFields["vehicleSeatingCapacity"] = get(vehicleSpecs, "seating");
    vehicleFields["vehicleColor"] = get(vehicleSpecs, "color");
  }
  return vehicleFields;
};
export const deriveAuctionFields = (listing: Property, organisation: Organisation): Record<string, any> => {
  const localeSettings = get(organisation, 'settings.locale', DEFAULT_LOCALE_SETTINGS) as LocaleSettingsProps;
  const auction = get(listing, 'auction', {});
  const unformattedAuctionStartPrice = get(auction, 'auctionStartPrice', 0);
  const isNumericAuctionStartPrice = isNumeric(unformattedAuctionStartPrice);
  return {
    auctionStartDate: String(get(auction, 'auctionStartDate', '')),
    auctionStartTime: String(get(auction, 'auctionStartTime', '')),
    auctionEndDate: String(get(auction, 'auctionEndDate', '')),
    auctionEndTime: String(get(auction, 'auctionEndTime', '')),
    auctionStartPrice: isNumericAuctionStartPrice ? formatAmount(Number(unformattedAuctionStartPrice), localeSettings) : unformattedAuctionStartPrice,
    auctionVenueAddress: String(get(auction, 'auctionVenueAddress', '')),
    auctionVenueName: String(get(auction, 'auctionVenueName', ''))
  };
};
export const deriveTemplateFieldLabel = (
  key: string,
  campaignType = CampaignGoalTypes.FlowFuel,
  templateType = TemplateTypes.PromoteListing
): string => {
  if (
    key === DerivedFieldKeys.BackgroundImage &&
    campaignType === CampaignGoalTypes.FlowBrand &&
    templateType !== TemplateTypes.SoldStock
  ) {
    return "Select Background";
  }
  return startCase(key);
};

export const deriveTemplateFieldInputType = (
  key: DerivedFieldKeys,
  campaignType = CampaignGoalTypes.FlowFuel,
  templateType = TemplateTypes.PromoteListing
): InputTypes => {
  if (
    key === DerivedFieldKeys.BackgroundImage &&
    campaignType === CampaignGoalTypes.FlowBrand &&
    templateType !== TemplateTypes.SoldStock
  ) {
    return InputTypes.File;
  }
  else if (
    Object.values(DerivedFieldKeys).includes(key) ||
    Object.values(Entities).includes(key as any) ||
    key.startsWith(DerivedFieldKeys.ListingImage)
  ) return InputTypes.Derived;
  else return InputTypes.Text;
};

export const deriveTemplateFieldEntity = (key: DerivedFieldKeys): Entities | undefined => {
  if (
    [
      DerivedFieldKeys.OrganisationLogo,
      DerivedFieldKeys.OrganisationName,
      Entities.Organisation,
    ].includes(key)
  ) return Entities.Organisation;

  const agentKeys = [
    DerivedFieldKeys.AgentName,
    DerivedFieldKeys.AgentEmail,
    DerivedFieldKeys.AgentContactNumber,
    DerivedFieldKeys.AgentContactNumberUnformatted,
    DerivedFieldKeys.AgentAvatar,
    Entities.Agent,
  ];
  if (agentKeys.includes(key)) return Entities.Agent;

  const listingKeys = [
    DerivedFieldKeys.Price,
    DerivedFieldKeys.PropertyPrice,
    DerivedFieldKeys.Disposition,
    DerivedFieldKeys.PropertyStatus,
    DerivedFieldKeys.PropertyType,
    DerivedFieldKeys.Beds,
    DerivedFieldKeys.Baths,
    DerivedFieldKeys.BackgroundImage,
    DerivedFieldKeys.ErfSize,
    DerivedFieldKeys.FloorSize,
    DerivedFieldKeys.Parking,
    DerivedFieldKeys.AuctionStartDate,
    DerivedFieldKeys.AuctionStartTime,
    DerivedFieldKeys.AuctionEndDate,
    DerivedFieldKeys.AuctionEndTime,
    DerivedFieldKeys.AuctionStartPrice,
    DerivedFieldKeys.AuctionVenueAddress,
    DerivedFieldKeys.AuctionVenueName,
    DerivedFieldKeys.Title,
    DerivedFieldKeys.Description,
    DerivedFieldKeys.Status,
    DerivedFieldKeys.Size,
    DerivedFieldKeys.Condition,
    DerivedFieldKeys.Category,
    DerivedFieldKeys.SubCategory,
    DerivedFieldKeys.Group,
    DerivedFieldKeys.Gender,
    DerivedFieldKeys.Color,
    DerivedFieldKeys.Material,
    DerivedFieldKeys.Pattern,
    DerivedFieldKeys.Length,
    DerivedFieldKeys.Height,
    DerivedFieldKeys.Width,
    DerivedFieldKeys.Weight,
    Entities.Listing,
    Entities.Property,
  ];
  if (listingKeys.includes(key) || key.startsWith(DerivedFieldKeys.ListingImage || key.startsWith("vehicle")))
    return Entities.Listing;

  if (
    [
      DerivedFieldKeys.Suburb,
      Entities.Address,
      DerivedFieldKeys.FullAddress,
      DerivedFieldKeys.Location,
      DerivedFieldKeys.AddressLine1,
      DerivedFieldKeys.AddressLine2,
      DerivedFieldKeys.City,
      DerivedFieldKeys.Province,
      DerivedFieldKeys.Country,
      DerivedFieldKeys.PostCode,
    ].includes(key)
  ) return Entities.Address;

  return undefined;
};

export const deriveTemplateFieldEntityField = (key: DerivedFieldKeys): string => {
  switch (key) {
    case DerivedFieldKeys.OrganisationLogo:
      return 'logoUrl';
    case DerivedFieldKeys.OrganisationName:
      return 'name';
    case DerivedFieldKeys.AgentName:
      return 'displayName';
    case DerivedFieldKeys.AgentEmail:
      return DerivedFieldKeys.Email;
    case DerivedFieldKeys.AgentContactNumber:
    case DerivedFieldKeys.AgentContactNumberUnformatted:
      return DerivedFieldKeys.ContactNumber;
    case DerivedFieldKeys.AgentAvatar:
      return 'profileImageUrl';
    case DerivedFieldKeys.PropertyPrice:
      return DerivedFieldKeys.Price;
    case DerivedFieldKeys.PropertyStatus:
      return DerivedFieldKeys.Disposition;
    case DerivedFieldKeys.Location:
      return DerivedFieldKeys.Suburb;
    case DerivedFieldKeys.Beds:
      return DerivedFieldKeys.Bedrooms;
    case DerivedFieldKeys.Baths:
      return DerivedFieldKeys.Bathrooms;
    case DerivedFieldKeys.BackgroundImage:
      return DerivedFieldKeys.Image;
    default: {
      if (key.startsWith(DerivedFieldKeys.ListingImage)) return DerivedFieldKeys.Image;
      else if (key.startsWith("vehicle")) return "vehicle";
      return key;
    }
  }
};

export const deriveFieldToMapAttribute = (key: string): string => {
  switch (key) {
    case DerivedFieldKeys.OrganisationLogo:
    case DerivedFieldKeys.AgentAvatar:
    case DerivedFieldKeys.BackgroundImage:
      return 'src';
    default: {
      if (key.startsWith(DerivedFieldKeys.ListingImage)) return 'src';
      return 'innerText';
    }
  }
};

export const deriveTemplateFields = (
  fieldsToMap: FieldsToMap[],
  campaignType = CampaignGoalTypes.FlowFuel,
  templateType = TemplateTypes.PromoteListing
): TemplateField[] | {
  templateFields: TemplateField[],
  keyMappings?: Record<string, string>,
} => {
  if (isEmpty(fieldsToMap)) return [];

  const templateFields: TemplateField[] = [];
  const keyMappings: Record<string, string> = {};

  const dependencyFields: Record<string, boolean> = {};
  for (let i = 0; i < fieldsToMap.length; i++) {
    if (!fieldsToMap[i]) continue;

    const fieldToMap = fieldsToMap[i];

    let entity, entityField, isRequired = isNil(fieldToMap.isRequired) ? true : fieldToMap.isRequired;

    let key = fieldToMap.id as any;
    if (key.startsWith(DerivedFieldKeys.ListingImageDep)) {
      key = key.replace(/[.]+/g, '-') as any;
      keyMappings[key] = fieldToMap.id;
    }

    const label = deriveTemplateFieldLabel(key, campaignType, templateType);
    const inputType = deriveTemplateFieldInputType(key, campaignType, templateType);

    if (inputType === InputTypes.Derived) {
      entity = deriveTemplateFieldEntity(key);
      entityField = deriveTemplateFieldEntityField(key);
      isRequired = false;

      if (entity && !dependencyFields[entity]) {
        let dependencyLabel = '', dependencyInputType = InputTypes.Text;
        if ([Entities.Listing, Entities.Property].includes(entity)) {
          dependencyLabel = 'Select Listing';
          dependencyInputType = InputTypes.Listing;
        } else if (entity === Entities.Agent) {
          dependencyLabel = 'Agent';
          dependencyInputType = InputTypes.Agent;
        } else if (entity === Entities.Organisation) {
          dependencyLabel = 'Selected Organisation';
          dependencyInputType = InputTypes.Organisation;
        } else if (entity === Entities.Address) {
          dependencyLabel = 'Suburb';
          dependencyInputType = InputTypes.Address;
        }

        templateFields.unshift({
          key: entity,
          label: dependencyLabel,
          inputType: dependencyInputType,
          value: key === entity ? fieldToMap.value : undefined,
          isRequired: true,
          index: 0,
        });

        dependencyFields[entity] = true;
      }
    }

    if (Object.values(Entities).includes(key)) continue;

    templateFields.push({
      key,
      label,
      inputType,
      entity,
      entityField,
      value: fieldToMap.value,
      isRequired,
      index: i,
    });
  }

  const agentField = templateFields.find(field => field.key === Entities.Agent);
  if (!agentField) {
    templateFields.unshift({
      key: Entities.Agent,
      label: 'Agent',
      inputType: InputTypes.Agent,
      isRequired: true,
      index: 0,
    });
  }

  if (!isEmpty(keyMappings)) return { templateFields, keyMappings };
  return templateFields;
}

export const deriveFieldsToMapFromKeyValues = (
  templateFieldValues: Record<string, any>,
  templateFieldKeyMappings: Record<string, string> = {}
): FieldsToMap[] => {
  if (isEmpty(templateFieldValues)) return [];

  const keys = Object.keys(templateFieldValues);

  const fieldsToMap: FieldsToMap[] = [];

  for (const key of keys) {
    if (!templateFieldValues[key]) continue;

    let value = templateFieldValues[key];
    if (isString(value) && /^https?:\/\//.test(value)) value = getUrlViaProxy(value);

    switch (key) {
      case Entities.Organisation:
      case Entities.Agent:
      case Entities.Property:
      case Entities.Address:
        continue;
      default:
        fieldsToMap.push({
          id: templateFieldKeyMappings[key] || key,
          attribute: deriveFieldToMapAttribute(key),
          value,
        });
        break;
    }
  }

  return fieldsToMap;
}

export const deriveImageMappings = (listing: ListingData, fieldsToMap: FieldsToMap[]): Record<string, string> => {
  const imageFieldsToMap = fieldsToMap.filter(({ id }) => {
    return id.startsWith(DerivedFieldKeys.ListingImageDep) || id.startsWith(DerivedFieldKeys.ListingImage);
  });
  if (isEmpty(imageFieldsToMap)) return {};

  const images = listing ? listingHelper.getListingImages(listing) : [];
  const imagesLength = size(images) || 1;
  const imageFields: Record<string, string> = {};
  let imageIdx = 0;
  for (const imageFieldToMap of imageFieldsToMap) {
    const url = get(images, `[${imageIdx}].media.sizes.medium`, get(images, `[${imageIdx}].media.url`, get(images, `[${imageIdx}].url`, '')));
    const imageUrl = getProtocolCompatibleImageUrl(url) || url;
    const key = imageFieldToMap.id.replace(/[.]+/g, '-');
    if (imageFieldToMap.id.startsWith(DerivedFieldKeys.ListingImageDep)) imageFields[imageFieldToMap.id] = imageUrl;
    imageFields[key] = imageUrl;
    imageIdx = ++imageIdx % imagesLength;
  }

  return imageFields;
}
