import { get, isArray, isEmpty, omitBy, toUpper } from 'lodash';

import { AuthenticatedUser } from '../store/auth';
import { AgentOption, ContentType, Image, ListingData, ListingTypes, Product, Property, PropertyOverviewFields } from '../store/properties/types';
import { Unit } from '../store/units';
import { getUnitPrice } from './units.helper';
import { Organisation } from '../store/organisation';
import { DEFAULT_LOCALE_SETTINGS } from './defaultLocaleSettings';
import { isNumeric } from './general';
import { formatAmount } from './numberFormatter';
import { FieldsToMap } from '../store/socials';

export const getPropertyFloorSize = (property: Property): number | null | undefined => {
  let floorSize = property.size;
  if (!floorSize && isArray(property.propertyOverView)) {
    const _floorSize = property.propertyOverView.find(prop => prop.name === PropertyOverviewFields.floorSize);
    if (_floorSize) floorSize = _floorSize.value as number;
  }

  return floorSize;
};

export const getPropertyErfSize = (property: Property): number | null | undefined => {
  let erfSize = property.size;
  if (!erfSize && isArray(property.propertyOverView)) {
    const _erfSize = property.propertyOverView.find(prop => prop.name === PropertyOverviewFields.erfSize);
    if (_erfSize) erfSize = _erfSize.value as number;
  }

  return erfSize;
};

export const deriveAgentOption = (agent: AuthenticatedUser): AgentOption => {
  const { _id, id, firstName, lastName, email, contactNumber, profileImageUrl } = agent;

  const displayname = `${firstName} ${lastName}`;

  return {
    label: displayname,
    value: id || _id,
    firstName,
    lastName,
    profileImageUrl,
    contact: {
      email,
      contactNumber,
    }
  };
};

/**
 * Validate latitude
 * @param {Number} latitude
 * @returns
 */
export const isLatitude = (latitude: number): boolean => {
  return isFinite(latitude) && Math.abs(latitude) <= 90;
}

/**
 * Validate longitude
 * @param {Number} longitude
 * @returns
 */
export const isLongitude = (longitude: number): boolean => {
  return isFinite(longitude) && Math.abs(longitude) <= 180;
}

/**
 * Validate coordinates
 * @param {*} latitude
 * @param {*} longitude
 * @returns
 */
export const validateCoordinates = (latitude: number, longitude: number, allowNullIsland = false): boolean => {
  const isNullIsland = latitude === 0 && longitude === 0;
  const nullIslandValidation = allowNullIsland ? true : !isNullIsland;
  return isLatitude(latitude) && isLongitude(longitude) && nullIslandValidation;
};

export const getPropertyUnit = (property: Property): Unit | undefined => {
  const units = get(property, 'units') || [];
  const random = Math.floor(Math.random() * units.length);
  const unit = units[random];

  return unit;
};

export const getListingPrice = (listing: ListingData): number | string | null | undefined => {
  let price;

  switch (listing.contentType) {
    case ContentType.PROPERTY: {
      const unit = getPropertyUnit(listing as Property);
      price = getUnitPrice(unit);
      break;
    }
    case ContentType.PRODUCT: {
      price = get(listing, 'displayPrice', get(listing, 'price'));
      break;
    }
    default:
      price = null;
      break;
  }

  return price ? price : 'POA';
};

export const getFormattedListingPrice = (listing: ListingData, organisation: Organisation): string => {
  const localeSettings = get(organisation, 'settings.locale', DEFAULT_LOCALE_SETTINGS);

  let price = getListingPrice(listing) || "";
  if (isNumeric(price)) price = formatAmount(Number(price), localeSettings);

  return price as string;
};

export const getListingDisposition = (listing: ListingData): string => {
  switch (listing.contentType) {
    case ContentType.PROPERTY:
    case ContentType.PRODUCT:
      return get(listing, 'listingType');
    default:
      return '';
  }
};

export const deriveDisposition = (listing: ListingData): string => {
  const disposition = getListingDisposition(listing);
  const prefixDisposition = disposition === ListingTypes.Sale ? 'FOR ' : disposition === ListingTypes.Auction ? 'ON ' : 'TO ';
  return prefixDisposition.concat(toUpper(disposition || ''));
};

export const getListingImages = (listing: ListingData): Image[] => {
  switch (listing.contentType) {
    case ContentType.PROPERTY:
    case ContentType.PRODUCT:
      return get(listing, 'images') || [];
    default:
      return [];
  }
};

export const deriveProductFieldValue = (key: string, product: Product): string => {
  return get(product, key) || '';
};

export const deriveProductFieldsValues = (fieldsToMap: FieldsToMap[], product: Product): Record<string, any> => {
  const fieldsValues: Record<string, any> = {};
  for (const field of fieldsToMap) {
    fieldsValues[field.id] = deriveProductFieldValue(field.id, product);
  }
  return omitBy(fieldsValues, isEmpty);
};
