import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import {
  FbSocialAsset,
  MoveFbPageToOrgPayload,
  Organisation,
  OrganisationState,
  PostAuthOrgInitPayload,
  SmartFeedSettings,
  SmartFeedTrigger,
  RemoveUserFbPagePayload,
  PostAuthOrgInitResponsePayload,
  SmartFeedEventRequest,
} from "./types";

export const updateOrganisation = createRoutine(
  "@@organisation/UPDATE_ORGANISATION"
);
export const updateOrganisationPromise = promisifyRoutine(updateOrganisation);

export const addOrganisationServiceArea = createRoutine(
  "@@organisation/ADD_ORGANISATION_SERVICE_AREA"
);
export const addOrganisationServiceAreaPromise = promisifyRoutine(
  addOrganisationServiceArea
);

export const updateNotificationRecipients = createRoutine(
  "@@organisation/UPDATE_NOTIFICATION_RECIPIENTS"
);
export const updateNotificationRecipientsPromise = promisifyRoutine(
  updateNotificationRecipients
);

export const updateDefaultNotificationRecipient = createRoutine(
  "@@organisation/UPDATE_DEFAULT_NOTIFICATION_RECIPIENT"
);
export const updateDefaultNotificationRecipientPromise = promisifyRoutine(
  updateDefaultNotificationRecipient
);

export const removeNotificationRecipients = createRoutine(
  "@@organisation/REMOVE_NOTIFICATION_RECIPIENTS"
);
export const removeNotificationRecipientsPromise = promisifyRoutine(
  removeNotificationRecipients
);

export const removeOrganisationServiceArea = createRoutine(
  "@@organisation/REMOVE_ORGANISATION_SERVICE_AREA"
);
export const removeOrganisationServiceAreaPromise = promisifyRoutine(
  removeOrganisationServiceArea
);

export const fetchOrganisationByMember = createRoutine(
  "@@organisation/FETCH_ORGANISATIONS"
);
export const fetchOrganisationByMemberPromise = promisifyRoutine(
  fetchOrganisationByMember
);

export const uploadLogo = createRoutine(
  "@@organisations/UPLOAD_ORGANISATION_LOGO"
);

export const uploadCoverImage = createRoutine(
  "@@organisations/UPLOAD_ORGANISATION_COVER_IMAGE"
);

export const setOrganisationSettings = createRoutine(
  "@@organisations/SET_ORGANISATION_SETTINGS"
);
export const setOrganisationSettingsPromise = promisifyRoutine(
  setOrganisationSettings
);

export const setNotifications = createRoutine(
  "@@organisations/SET_NOTIFICATIONS"
);
export const setNotificationsPromise = promisifyRoutine(setNotifications);

export const setCampaignSettings = createRoutine(
  "@@organisations/SET_CAMPAIGN_SETTINGS"
);
export const setCampaignSettingsPromise = promisifyRoutine(setCampaignSettings);

export const setContentSettings = createRoutine(
  "@@organisations/SET_CONTENT_SETTINGS"
);
export const setContentSettingsPromise = promisifyRoutine(setContentSettings);

export const setColors = createRoutine("@@organisations/SET_COLORS");
export const setColorsPromise = promisifyRoutine(setColors);

export const setTestMode = createRoutine("@@organisations/SET_TEST_MODE");
export const setTestModePromise = promisifyRoutine(setTestMode);

export const setPaymentMethods = createRoutine(
  "@@organisations/SET_PAYMENT_METHODS"
);
export const setPaymentMethodsPromise = promisifyRoutine(setPaymentMethods);

export const setEngagementMaximiser = createRoutine(
  "@@organisations/SET_ENGAGEMENT_MAXIMISER"
);
export const setEngagementMaximiserPromise = promisifyRoutine(
  setEngagementMaximiser
);

export const setLocaleSettings = createRoutine(
  "@@organisations/SET_LOCALE_SETTINGS"
);
export const setLocaleSettingsPromise = promisifyRoutine(setLocaleSettings);

export const fetchAllOrganisations = createRoutine(
  "@@organisations/GET_ALL_ORGANISATIONS"
);
export const fetchAllOrganisationsPromise = promisifyRoutine(
  fetchAllOrganisations
);

export const fetchAllHeadOfficeOrganisations = createRoutine(
  "@@organisations/GET_ALL_HEAD_OFFICE_ORGANISATIONS"
);
export const fetchAllHeadOfficeOrganisationsPromise = promisifyRoutine(
  fetchAllHeadOfficeOrganisations
);

export const fetchOrganisationByName = createRoutine(
  "@@organisations/GET_ORGANISATION_BY_NAME"
);
export const fetchOrganisationByNamePromise = promisifyRoutine(
  fetchOrganisationByName
);

export const selectOrganisation = createRoutine(
  "@@organisations/SET_ORGANISATION"
);

export const fetchOrganisationById = createRoutine(
  "@@organisation/FETCH_ORGANISATION_BY_ID"
);
export const fetchOrganisationByIdPromise = promisifyRoutine(
  fetchOrganisationById
);

export const setSyndicatorSettings = createRoutine(
  "@@organisations/SET_SYNDICATOR_SETTINGS"
);
export const setSyndicatorSettingsPromise = promisifyRoutine(
  setSyndicatorSettings
);

export const runSyndicatorNow = createRoutine("@@organisation/RUN_SYNDICATOR_NOW");
export const runSyndicatorNowPromise = promisifyRoutine(runSyndicatorNow);

// Members
export const fetchMemberRoles = createRoutine(
  "@@organisation/GET_MEMBER_ROLES"
);
export const fetchMemberRolesPromise = promisifyRoutine(fetchMemberRoles);

export const fetchMembersWithSubOrgMembers = createRoutine(
  "@@organisations/FETCH_MEMBERS_WITH_SUB_ORG_MEMBERS"
);
export const fetchMembersWithSubOrgMembersPromise = promisifyRoutine(
  fetchMembersWithSubOrgMembers
);

export const createMember = createRoutine("@@organisations/CREATE_MEMBER");
export const createMemberPromise = promisifyRoutine(createMember);

export const updateMember = createRoutine("@@organisation/ASSIGN_ROLE");
export const updateMemberPromise = promisifyRoutine(updateMember);

export const updateUserMember = createRoutine(
  "@@organisation/UDPATE_USER_MEMBER"
);
export const updateUserMemberPromise = promisifyRoutine(updateUserMember);

export const inviteMember = createRoutine("@@organisations/ADD_NEW_MEMBER");
export const inviteMemberPromise = promisifyRoutine(inviteMember);

export const updateMemberUserDetails = createRoutine(
  "@@organisations/UPDATE_MEMBER_USER_DETAILS"
);

export const updateMemberUserProfileImage = createRoutine(
  "@@organisations/UPDATE_MEMBER_USER_PROFILE_IMAGE"
);

export const openInvite = createRoutine("@@organisations/OPEN_INVITE_FORM");
export const deleteMember = createRoutine("@@organisationUPDATE_MEMBER");
export const deleteMemberPromise = promisifyRoutine(deleteMember);

export const addMemberToOrganisationServiceArea = createRoutine(
  "@@organisation/ADD_MEMBER_TO_ORGANISATION_SERVICE_AREA"
);
export const addMemberToOrganisationServiceAreaPromise = promisifyRoutine(
  addMemberToOrganisationServiceArea
);

export const removeMemberFromOrganisationServiceArea = createRoutine(
  "@@organisation/REMOVE_MEMBER_FROM_ORGANISATION_SERVICE_AREA"
);
export const removeMemberFromOrganisationServiceAreaPromise = promisifyRoutine(
  removeMemberFromOrganisationServiceArea
);

//#region Social Asset Config
export const setSocialAssetConfig = createRoutine(
  "@@socials/UPDATE_SOCIALASSET_CONFIG"
);
export const setSocialAssetConfigPromise =
  promisifyRoutine(setSocialAssetConfig);
//#endregion
export const updateMemberProfileImage = createRoutine(
  "@@organisation/UPDATE_PROFILE"
);
export const updateMemberProfileImagePromise = promisifyRoutine(
  updateMemberProfileImage
);

// Sub-organisation routes
export const fetchSubOrganisationById = createRoutine(
  "@@organisation/GET_SUB_ORGANISATION"
);
export const fetchSubOrganisationByIdPromise = promisifyRoutine(
  fetchSubOrganisationById
);

export const selectSubOrganisation = createRoutine(
  "@@organisation/SET_SUB_ORGANISATION"
);
export const toggleFullAddress = createRoutine(
  "@@organisations/TOGGLE_FULL_ADDRESS"
);
export const updateShowPartners = createRoutine(
  "@@properties/UPDATE_SHOW_PARTNERS"
);
export const updateShowReferralPartners = createRoutine(
  "@@properties/UPDATE_SHOW_REFERRAL_PARTNERS"
);

export const toggleFullAddressPromise = promisifyRoutine(toggleFullAddress);
export const updateShowPartnersPromise = promisifyRoutine(updateShowPartners);

export const updateShowReferralPartnersPromise = promisifyRoutine(
  updateShowReferralPartners
);
export const resendMemberInvitation = createRoutine(
  "@@organisation/SEND_MEMBER_INVITATION"
);
export const resendMemberInvitationPromise = promisifyRoutine(
  resendMemberInvitation
);

export const fetchInvitedMembers = createRoutine(
  "@@organisation/GET_INVITED_MEMBERS_BY_ORG_ID"
);
export const fetchInvitedMembersPromise = promisifyRoutine(fetchInvitedMembers);

export const patchOrganisation = createRoutine(
  "@@organisation/PATCH_ORGANISATION"
);
export const patchOrganisationPromise: (
  payload: Partial<Organisation>
) => Promise<any> = promisifyRoutine(patchOrganisation);

export const fetchPendingOrganisationSubscription = createRoutine(
  "@@socials/FETCH_PENDING_ORGANISATION_SUBSCRIPTION"
);
export const fetchPendingOrganisationSubscriptionPromise = promisifyRoutine(
  fetchPendingOrganisationSubscription
);

export const patchOrganisationState = createRoutine(
  "@@organisation/PATCH_ORGANISATION_STATE"
);
export const patchOrganisationStatePromise: (
  payload: Partial<OrganisationState>
) => Promise<any> = promisifyRoutine(patchOrganisationState);

export const patchFbSocialAsset = createRoutine(
  "@@organisation/PATCH_FB_SOCIAL_ASSET"
);
export const patchFbSocialAssetPromise: (
  payload: Partial<FbSocialAsset>
) => Promise<any> = promisifyRoutine(patchFbSocialAsset);

export const patchSubOrgFbSocialAsset = createRoutine(
  "@@organisation/PATCH_SUB_ORG_FB_SOCIAL_ASSET"
);
export const patchSubOrgFbSocialAssetPromise: (
  payload: Partial<FbSocialAsset>
) => Promise<any> = promisifyRoutine(patchSubOrgFbSocialAsset);

export const postAuthOrgInit = createRoutine<PostAuthOrgInitPayload>(
  "@@organisation/POST_AUTH_ORG_INIT"
);
export const postAuthOrgInitPromise = promisifyRoutine(postAuthOrgInit) as (
  payload: PostAuthOrgInitPayload
) => Promise<PostAuthOrgInitResponsePayload>;

export const patchSmartFeedSettings = createRoutine(
  "@@organisation/PATCH_SMART_FEED_SETTINGS"
);
export const patchSmartFeedSettingsPromise: (
  payload: Partial<SmartFeedSettings>
) => Promise<any> = promisifyRoutine(patchSmartFeedSettings);

export const getSmartFeedEvents = createRoutine(
  "@@organisation/GET_SMART_FEED_EVENTS"
);
export const getSmartFeedEventsPromise: (
  payload: Partial<SmartFeedEventRequest>
) => Promise<any> = promisifyRoutine(getSmartFeedEvents);

export const retrySmartFeedEvent = createRoutine(
  "@@organisation/RETRY_SMART_FEED_EVENT"
);
export const retrySmartFeedEventPromise: (
  payload: Partial<{ event: any }>
) => Promise<any> = promisifyRoutine(retrySmartFeedEvent);

export const triggerSmartFeedSyndicator = createRoutine(
  "@@organisation/TRIGGER_SMART_FEED_SYNDICATOR"
);

export const triggerSmartFeedSyndicatorPromise: (
  payload: Partial<SmartFeedTrigger>
) => Promise<any> = promisifyRoutine(triggerSmartFeedSyndicator);
export const moveFbPageToOrg = createRoutine(
  "@@organisation/MOVE_FB_PAGE_TO_ORG"
);
export const moveFbPageToOrgPromise: (
  payload: MoveFbPageToOrgPayload
) => Promise<any> = promisifyRoutine(moveFbPageToOrg);

export const removeUserFbPage = createRoutine(
  "@@organisation/REMOVE_USER_FB_PAGE"
);
export const removeUserFbPagePromise: (
  payload: RemoveUserFbPagePayload
) => Promise<any> = promisifyRoutine(removeUserFbPage);
