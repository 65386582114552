import React from "react";

import MuiButton, { ButtonProps } from "@material-ui/core/Button";
import MuiCircularProgress, {
  CircularProgressProps,
} from "@material-ui/core/CircularProgress";
import styled, { mediaDown } from "../../utils/styledComponents";

interface OwnProps {
  loading?: boolean;
  isActive?: boolean;
  isAdmin?: boolean;
}

export interface StyledButton {
  primary?: boolean;
  danger?: boolean;
  secondary?: boolean;
  success?: boolean;
  default?: boolean;
  text?: boolean;
  outline?: boolean;
}

export type AllProps = StyledButton & ButtonProps;

const StyledProgress = styled(
  MuiCircularProgress as React.FunctionComponent<CircularProgressProps>
)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -12px;
  margin-left: -12px;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledButton = styled(MuiButton)`
  && {
    border-radius: 4px !important;
    height: 40px;
    padding: 5px 5px !important;

    ${mediaDown.sm`height: 30px;`}
  }
`;

const Button: React.FunctionComponent<OwnProps & AllProps> = ({
  loading,
  isActive,
  isAdmin,
  ...props
}) => (
  <>
    <StyledButton {...props} />
    {loading && <StyledProgress size={24} color="inherit" />}
  </>
);

export default Button;
